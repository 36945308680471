import {Button, DatePicker, Form, Input, message, Select, Switch} from "antd";
import Icon from '@ant-design/icons';
import React, {useState} from "react";
import {callApi, useGet, usePost} from "../../common/hooks";
import {PostRequest, SeasonInfo} from "../../common/interfaces";
import moment from "moment";
import {CSVLink} from "react-csv";
import {SeasonDiscountEditor} from "../components/SeasonDiscountEditor";

const emptySeason = {
  id: -1,
  name: "",
  startingDay: "",
  registrationStartDay: "",
  registrationEndDay: "",
  isActive: false,
  isOpenForRegistration: false,
  order: Number.MAX_VALUE,
  showRegistrationBanner: false
} as SeasonInfo;

interface PlayerInfo {
  name: string;
  jerseyNumber: string;
}

export function Seasons() {
  const seasons = useGet<SeasonInfo[]>("/api/season/all");
  const updatePostReq = usePost({
    uri: "/api/updateSeason",
    deps: [seasons]
  });
  const [selectedSeason, setSelectedSeason] = useState<SeasonInfo>(emptySeason);

  const allSeasons = (seasons.data || []).sort((s1, s2) => s2.order - s1.order);
  const seasonsById = new Map(allSeasons.map(s => [s.id, s]));
  seasonsById.set(-1, emptySeason);

  return (
      <SeasonForm
          seasonMap={seasonsById}
          season={selectedSeason}
          postReq={updatePostReq}
          updateSeason={setSelectedSeason}
      />
  );
}

interface SeasonFormProps {
  season: SeasonInfo;
  postReq: PostRequest;
  seasonMap: Map<number, SeasonInfo>;
  updateSeason: (value: SeasonInfo) => void;
}

interface SeasonFormValues {
  seasonId: number;
  label: string;
  startingDay: moment.Moment;
  registrationStartDate: moment.Moment;
  registrationEndDate: moment.Moment;
  showRegistrationBanner: boolean;
}

export function SeasonForm(props: SeasonFormProps) {
  const {season, postReq, updateSeason, seasonMap} = props;
  const [form] = Form.useForm();
  const seasonId = Form.useWatch('seasonId', form);
  const [playerData, setPlayerData] = useState<PlayerInfo[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedSeason, setSelectedSeason] = useState<SeasonInfo>(props.season);
  const playerListCache = new Map<number, PlayerInfo[]>();

  const fetchPlayersList = (seasonId: number) => {
    if (playerListCache.has(seasonId)) {
      setPlayerData(playerListCache.get(seasonId)!);
      return;
    }

    setLoading(true);
    callApi<PlayerInfo[]>(`/api/getSeasonPlayers?seasonId=${seasonId}`)
        .then((players: PlayerInfo[]) => {
          playerListCache.set(seasonId, players);
          setPlayerData(players);
          setLoading(false);
        }).catch(() => {
      setPlayerData([]);
      setLoading(false);
    });
  };

  const dateFormat = "YYYY-MM-DD";

  const onSubmit = (values: SeasonFormValues) => {
    form.validateFields()
        .then((validValues: SeasonFormValues) => {
          postReq.send({
            seasonId: validValues.seasonId, label: validValues.label,
            startingDay: validValues.startingDay.format(dateFormat),
            registrationStartDate: validValues.registrationStartDate.format(dateFormat),
            registrationEndDate: validValues.registrationEndDate.format(dateFormat),
            showRegistrationBanner: validValues.showRegistrationBanner
          }, () => {
            message.success("Information updated!");
            updateSeason(emptySeason);
          });
        });
  };

  const formItemLayout = {
    labelCol: {span: 6},
    wrapperCol: {span: 8}
  };

  const allSeasons = Array.from(seasonMap.values()).sort((s1, s2) => s2.order - s1.order);
  const getDate = (dateText: string) => {
    if (dateText === '') {
      return '';
    }
    return moment(dateText, dateFormat);
  };

  return (
      <Form form={form} onFinish={onSubmit} layout={"horizontal"}>
        <div style={{
          display: "flex",
        }}>
          <Form.Item name={"seasonId"}
                     initialValue={-1}
                     {...formItemLayout}>
            <Select
                onChange={(val: number) => {
                  const season = seasonMap.get(val);
                  fetchPlayersList(season!.id);

                  form.setFieldValue('label', season!.name);

                  form.setFieldValue('startingDay', getDate(season!.startingDay));
                  form.setFieldValue('registrationStartDate', getDate(season!.registrationStartDay));
                  form.setFieldValue('registrationEndDate', getDate(season!.registrationEndDay));
                  form.setFieldValue('showRegistrationBanner', season!.showRegistrationBanner);
                  setSelectedSeason(season!);
                }}
                style={{width: 250, marginBottom: 10}}>
              {allSeasons.map(s => (
                  <Select.Option key={s.id} value={s.id}>
                    {s.id === -1 ? 'New Season' : s.name}
                  </Select.Option>
              ))}
            </Select>
          </Form.Item>
          {!loading && seasonId !== -1 && <CSVLink
              data={playerData}
              headers={[
                {label: "Name", key: "name"},
                {label: "Jersey Number", key: "jerseyNumber"}
              ]}
              filename={"registrations.csv"}
              className={"ant-btn ant-btn-primary"}
              style={{
                marginLeft: 5,
              }}
          >
              <Icon type={"download"}/> Download Player List
          </CSVLink>}

        </div>
        <Form.Item
            name={"label"}
            label={"Label"}
            initialValue={season.name}
            rules={[{required: true, message: "Season label is required."}]}
            {...formItemLayout}>
          <Input/>
        </Form.Item>
        <Form.Item
            name={"startingDay"}
            label={"Season Start Day"}
            rules={[{required: true, message: "Season start day is required."}]}
            initialValue={getDate(season.startingDay)}
            {...formItemLayout}>
          <DatePicker format={dateFormat}></DatePicker>
        </Form.Item>
        <Form.Item
            name={"registrationStartDate"}
            label={"Registration Start Day"}
            rules={[{required: true, message: "Registration start day is required."}]}
            initialValue={getDate(season.registrationStartDay)}
            {...formItemLayout}>
          <DatePicker format={dateFormat}></DatePicker>
        </Form.Item>
        <Form.Item
            name={"registrationEndDate"}
            label={"Registration End Day"}
            rules={[{required: true, message: "Registration end day is required."}]}
            initialValue={getDate(season.registrationEndDay)}
            {...formItemLayout}>
          <DatePicker format={dateFormat}></DatePicker>
        </Form.Item>
        <Form.Item
            name={"showRegistrationBanner"}
            label={"Show banner in players app?"}
            initialValue={season.showRegistrationBanner}
            {...formItemLayout}>
          <Switch />
        </Form.Item>
        <Form.Item>
          <Button
              type={"primary"}
              htmlType={"submit"}
              loading={postReq.isLoading}>
            {seasonId > 0 ? "Save" : "Create Season"}
          </Button>
        </Form.Item>
        {seasonId > 0 && <SeasonDiscountEditor seasonId={selectedSeason.id} seasonOrder={selectedSeason.order}/>}
      </Form>
  );
}
