import React, { useState } from "react";
import {Route, Routes, useNavigate} from "react-router";
import {HOME, REGISTER, RosterUrl, ScoresheetUrl} from "../common/routes";
import { GamesList } from "./pages/GamesList";
import { Roster } from "../common/pages/Roster";
import { Scoresheet } from "./pages/Scoresheet";
import { Layout, Menu, Button, Dropdown } from "antd";
import {
  SCHEDULE,
  SUBBING,
  SETTINGS,
  SCRIMMAGES, DONATE, PASSWORD_RESET, SubListUrl, StatsUrl, SeasonRegistrationUrl
} from "./routes";
import { Stats } from "./pages/Stats";
import { Subbing } from "./pages/Subbing";
import { SubList } from "./pages/SubList";
import { NotFound } from "../common/pages/NotFound";
import { Dashboard } from "./pages/Dashboard";
import { Settings } from "./pages/Settings";
import { IS_MOBILE } from "../common/utils";
import { useCookies } from "react-cookie";
import { useUserContext } from "../common/contexts";
import { Logo } from "../common/components/Logo";
import { Scrimmages } from "./pages/Scrimmages";
import {Donate} from "./pages/Donate";
import {SeasonRegistration} from "./pages/SeasonRegistration";
import {PasswordReset} from "./pages/PasswordReset";
import {Registration} from "./pages/Registration";
import {
  CalculatorOutlined,
  CalendarOutlined,
  FireOutlined,
  HomeOutlined,
  LogoutOutlined,
  MenuOutlined,
  SettingOutlined,
  TeamOutlined
} from "@ant-design/icons";

export function PlayersApp() {
  const [, , removeCookie] = useCookies(["username", "authToken"]);
  const { setUser } = useUserContext();
  const [menuVisible, setMenuVisible] = useState<boolean>(false);
  let navigate = useNavigate();

  const onLogout = () => {
    removeCookie("username", { path: "/" });
    removeCookie("authToken", { path: "/" });
    setUser(undefined);
  };

  const goToRoute = (route: string) => {
    navigate(route);
    setMenuVisible(false);
  };

  const mainMenu = (
    <Menu
      theme={IS_MOBILE ? "light" : "dark"}
      mode={IS_MOBILE ? "vertical" : "inline"}
    >
      <Menu.Item key={"home"} onClick={() => goToRoute("/")}>
        <HomeOutlined />
        <span>Home</span>
      </Menu.Item>
      <Menu.Item key={"schedule"} onClick={() => goToRoute(SCHEDULE)}>
        <CalendarOutlined />
        <span>Schedule</span>
      </Menu.Item>
      <Menu.Item key={"stats"} onClick={() => goToRoute(StatsUrl.createUrl())}>
        <CalculatorOutlined />
        <span>Stats</span>
      </Menu.Item>
      <Menu.Item
        key={"scrimmages"}
        onClick={() => goToRoute(SCRIMMAGES)}
      >
        <FireOutlined />
        <span>Scrimmages</span>
      </Menu.Item>
      <Menu.Item key={"subbing"} onClick={() => goToRoute(SUBBING)}>
        <TeamOutlined />
        <span>Subbing</span>
      </Menu.Item>
      <Menu.Item key={"settings"} onClick={() => goToRoute(SETTINGS)}>
        <SettingOutlined />
        <span>Settings</span>
      </Menu.Item>
      {IS_MOBILE && (
        <Menu.Item key={"logout"} onClick={onLogout}>
          <LogoutOutlined />
          <span>Logout</span>
        </Menu.Item>
      )}
    </Menu>
  );

  return (
    <Layout style={{ background: "white" }}>
      {IS_MOBILE ? (
        <Layout.Header style={{ background: "white", padding: 0 }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center"
            }}
          >
            <Logo />
            <Dropdown
              overlay={mainMenu}
              placement={"bottomRight"}
              visible={menuVisible}
              onVisibleChange={visible => {
                if (!visible) {
                  setMenuVisible(false);
                }
              }}
            >
              <Button
                icon={<MenuOutlined />}
                size={"large"}
                onClick={() => setMenuVisible(!menuVisible)}
              />
            </Dropdown>
          </div>
        </Layout.Header>
      ) : (
        <Layout.Sider collapsed={true}>{mainMenu}</Layout.Sider>
      )}
      <Layout.Content style={{ paddingLeft: IS_MOBILE ? 0 : 10 }}>
        <Routes>
          <Route path={HOME} element={<Dashboard />} />
          <Route
            path={ScoresheetUrl.pattern}
            element={<Scoresheet />}
          />
          <Route path={SCHEDULE} element={<GamesList />} />
          <Route path={RosterUrl.pattern} element={<Roster />} />
          <Route path={StatsUrl.pattern} element={<Stats />} />
          <Route path={SETTINGS} element={<Settings />} />
          <Route
            path={SCRIMMAGES}
            element={<Scrimmages />}
          />
          <Route path={SUBBING} element={<Subbing />} />
          <Route path={SubListUrl.pattern} element={<SubList />} />
          <Route path={DONATE} element={<Donate />} />
          <Route
              path={SeasonRegistrationUrl.pattern}
              element={<SeasonRegistration />}
          />
          <Route
              path={PASSWORD_RESET}
              element={<PasswordReset />}
          />
          <Route
              path={REGISTER}
              element={<Registration />}
          />
          <Route path={"*"} element={<NotFound />} />
        </Routes>
      </Layout.Content>
    </Layout>
  );
}
