import React, {useState} from "react";
import {useGet, usePost} from "../../common/hooks";
import {formatISODate} from "../../common/utils";
import {Discount, PlayerType, PostRequest, SeasonInfo} from "../../common/interfaces";
import {CompactTable} from "../../common/components/CompactTable";
import {Button, Checkbox, DatePicker, Form, Input, InputNumber, Modal, Radio, Switch} from "antd";
import {DeleteOutlined, EditOutlined, PlusCircleOutlined} from "@ant-design/icons";
import moment from "moment";


interface DiscountTableData extends Discount {
  amount: string;
}

function toTableData(discount: Discount) {
  return {...discount, amount: discount.amtOff > 0 ? `$${discount.amtOff}` : `${discount.pctOff}%`} as DiscountTableData;
}

interface ModalData {
  show: boolean;
  data: DiscountTableData | undefined;
}

const playerTypeLabels = new Map<PlayerType, string>([[PlayerType.SKATER, "Skater"],
[PlayerType.GOALIE, "Goalie"], [PlayerType.SKATER_AND_GOALIE, "Skater & Goalie"]]);

export function SeasonDiscountEditor(props: { seasonId: number, seasonOrder: number }) {
  const { seasonId, seasonOrder } = props;

  const seasonInfo = useGet<SeasonInfo>(`/api/season?seasonId=${seasonOrder}`);

  const deletePostReq = usePost({
    uri: "/api/discounts/delete",
    deps: [seasonInfo]
  });
  const createPostReq = usePost({
    uri: "/api/discounts/create",
    deps: [seasonInfo]
  });
  const modifyPostReq = usePost({
    uri: "/api/discounts/modify",
    deps: [seasonInfo]
  });

  const [modal, setModal] = useState<ModalData>({ show: false } as ModalData);
  const hideModal = () => setModal({show: false} as ModalData);

  const discounts = (seasonInfo.data === undefined ? [] : seasonInfo.data.allDiscounts)
      .map(d => toTableData(d))
      .toSorted((d1, d2) => d1.description.localeCompare(d2.description));

  const noWrapSpan = (val: string) => (
      <span style={{ whiteSpace: "nowrap" }}>{val}</span>
  );

  const renderDelete = function(seasonId: number, req: PostRequest, val: string, row: DiscountTableData) {
    return (
        <span>
        <Button
            icon={<DeleteOutlined />}
            style={{color: "red", border: "none", padding: "0 0 0 10px"}}
            onClick={() => req.send({seasonId, discountId: row.id})} />
       <Button
           icon={<EditOutlined />}
           style={{color: "red", border: "none", padding: "0 0 0 10px"}}
           onClick={() => {
             setModal({show: true, data: row})
           }} />
      </span>
    );
  };

  const columns = [
    { dataIndex: "id" },
    { dataIndex: "description", title: "Description" },
    { dataIndex: "code", title: "Code" },
    { dataIndex: "isAutoApplied",
      title: "Applied Automatically?",
      render: (val: boolean) => (val ? "Yes" : ""),},
    {
      dataIndex: "eligibleRegistrationTypes",
      title: "Eligible Registration Types",
      render: (val: number[]) => noWrapSpan(val.length === 3 ? 'All' : val.map(v => playerTypeLabels.get(v)).join(", "))
    },
    { dataIndex: "amount", title: "Discount" },
    {
      dataIndex: "expirationDate",
      title: "Expiration",
      render: (val: string) => noWrapSpan(val == null ? 'N/A' : formatISODate(val, "MMM dd"))
    },
    {
      dataIndex: "buttons", title: "",
      render: (val: string, row: DiscountTableData) => renderDelete(seasonId, deletePostReq, val, row)
    }
  ];

  return (
      <>
        <AddButton
            text={"Create Discount"}
            disabled={seasonInfo.isLoading}
            onClick={() => {
              setModal({show: true} as ModalData)
            }}/>
        <CompactTable
            data={discounts}
            loading={seasonInfo.isLoading}
            columns={columns.filter(
                c => !c.dataIndex || c.dataIndex !== 'id'
            )}
            rowKey={"code"}
        />
        <Modal visible={modal.show} footer={null} onCancel={hideModal} destroyOnClose={true}>
          <DiscountForm
              seasonId={seasonId}
              discount={modal.data}
              postReq={modal.data === undefined ? createPostReq : modifyPostReq}
              hideModal={hideModal}
          />
        </Modal>
      </>
  );
}

function AddButton(props: { text: string; onClick: () => void, disabled: boolean }) {
  const { text, onClick, disabled } = props;
  return (
      <Button style={{ marginTop: "10px" }} type={"primary"} disabled={disabled} onClick={onClick}>
        <PlusCircleOutlined />
        {` ${text}`}
      </Button>
  );
}

interface DiscountFormProps {
  discount: DiscountTableData | undefined;
  seasonId: number;
  postReq: PostRequest;
  hideModal: () => void;
}

interface DiscountFormValues {
  discountId?: number;
  description: string;
  code: string;
  eligibleRegistrationTypes: number[];
  pctOff: number;
  amtOff: number;
  expirationDate: moment.Moment | null;
}

function generateRandomCode() {
  return  Math.random()
      .toString(36)
      .slice(2)
      .toUpperCase();
}

function DiscountForm(props: DiscountFormProps) {
  const { discount, seasonId, postReq, hideModal } = props;
  const [form] = Form.useForm<DiscountFormValues>();

  const onSuccess = () => {
    form.resetFields();
    hideModal();
  };
  const discountId = discount !== undefined ? discount.id : -1;
  const onSubmit = (values: DiscountFormValues) => {
    form.validateFields()
        .then((validValues: DiscountFormValues) => {
          let expirationDate = undefined;
          if (validValues.expirationDate != null) {
            expirationDate = validValues.expirationDate!.format('YYYY-MM-DD');
          }
          postReq.send( {
            description: validValues.description,
            code: validValues.code,
            eligibleRegistrationTypes: validValues.eligibleRegistrationTypes,
            pctOff: validValues.pctOff,
            amtOff: validValues.amtOff,
            expirationDate,
            seasonId, discountId }, onSuccess);
        });
  };

  const registrationTypes = [
    { label: playerTypeLabels.get(PlayerType.SKATER), value: PlayerType.SKATER },
    { label: playerTypeLabels.get(PlayerType.GOALIE), value: PlayerType.GOALIE },
    { label: playerTypeLabels.get(PlayerType.SKATER_AND_GOALIE), value: PlayerType.SKATER_AND_GOALIE }
  ];
  const discountTypes = [
    { label: 'Percentage', value: 'pctOff' }, // remember to pass the key prop
    { label: 'Fixed amount', value: 'amtOff' },
  ];

  const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 14 }
  };
  const arrayValidator = (rule: any, value: number[] | undefined) => {
    if (value === undefined || value.length === 0) {
      return Promise.reject(new Error("Must select at least one option."));
    }
    return Promise.resolve();
  };
  const getDate = (dateText: string | null) => {
    if (dateText == null || dateText === '') {
      return undefined;
    }
    return moment(dateText, "YYYY-MM-DD");
  };

  const codeRequired = Form.useWatch('codeRequired', form);
  const discountType = Form.useWatch('discountType', form);
  const isExistingDiscount = discountId > 0;

  return (
      <Form onFinish={onSubmit} layout={"horizontal"} form={form}>
        <Form.Item
            label={"Description"}
            name={"description"}
            initialValue={discount !== undefined ? discount.description : ""}
            rules={[{ required: true }]}
            {...formItemLayout}>
          <Input />
        </Form.Item>
        <Form.Item
            label={"Code required?"}
            name={"codeRequired"}
            initialValue={discount !== undefined && discount.code !== "" ? "checked" : "unchecked"}
            rules={[{ required: true }]}
            {...formItemLayout}>
          <Switch size="small" disabled={isExistingDiscount} />
        </Form.Item>
        <Form.Item
            label={"Code"}
            name={"code"}
            initialValue={discount !== undefined ? discount.code : generateRandomCode()}
            rules={[{ required: true }]}
            {...formItemLayout}>
          <Input disabled={!codeRequired} />
        </Form.Item>
        <Form.Item
            label={"Registration Types"}
            name={"eligibleRegistrationTypes"}
            initialValue={discount !== undefined ? discount.eligibleRegistrationTypes : []}
            rules={[{ validator: arrayValidator }]}>
          <Checkbox.Group disabled={discountId > 0} options={registrationTypes} />
        </Form.Item>
        <Form.Item
            label={"Discount Type"}
            name={"discountType"}
            rules={[{ required: true }]}
            initialValue={discount !== undefined && discount.amtOff > 0 ? "amtOff" : "pctOff"}
            {...formItemLayout}>
          <Radio.Group disabled={isExistingDiscount} options={discountTypes} />
        </Form.Item>
        <Form.Item
            label={"% Off"}
            name={"pctOff"}
            initialValue={discount !== undefined ? discount.pctOff : 0}
            rules={[{ required: discountType === "pctOff" }]}
            {...formItemLayout}>
          <InputNumber min={0} max={100} disabled={isExistingDiscount || discountType !== "pctOff"}></InputNumber>
        </Form.Item>
        <Form.Item
            label={"Amount Off"}
            name={"amtOff"}
            initialValue={discount !== undefined ? discount.amtOff : 0}
            rules={[{ required: discountType === "amtOff"}]}
            {...formItemLayout}>
          <InputNumber min={0} disabled={isExistingDiscount || discountType !== "amtOff"}></InputNumber>
        </Form.Item>
        <Form.Item
            name={"expirationDate"}
            label={"Expiration Date"}
            initialValue={discount !== undefined ? getDate(discount.expirationDate) : undefined}
            {...formItemLayout}>
          <DatePicker format={"YYYY-MM-DD"}></DatePicker>
        </Form.Item>
        <Form.Item>
          <Button
              type={"primary"}
              htmlType={"submit"}
              loading={postReq.isLoading}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
  );
}
