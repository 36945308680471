import React, {ReactText} from "react";
import {useGet, usePost} from "../../common/hooks";
import {useEnsuredUser} from "../../common/contexts";
import {Attendance, Game, SubbingPrefs} from "../../common/interfaces";
import {addTimeToNow, dtNow, nDaysFromNow, toModifyType, updateAvailability} from "../../common/utils";
import _ from "lodash";
import {Alert, Divider, Statistic} from "antd";
import {Link} from "react-router-dom";
import {AttendanceTable} from "../../common/components/AttendanceTable";
import {SCHEDULE, SeasonRegistrationUrl, SUBBING} from "../routes";
import Spinner from "../../common/components/Spinner";

interface BannerInfo {
  registrationSeason?: number;
}

export function Dashboard() {
  const user = useEnsuredUser();
  const bannerInfo = useGet<BannerInfo>(
      `/api/dashboardbannerinfo?personId=${user.personId}`
  );
  const prefs = useGet<SubbingPrefs | null>(
      `/api/subbing?personId=${user.personId}`
  );
  const games = useGet<Game[]>(`/api/games?personId=${user.personId}`);
  const upcomingGamesToCoach = useGet<Game[]>(
      `/api/coachingGames?personId=${user.personId}`
  );
  const postReq = usePost({
    uri: "/api/modifyPlayerAttendance",
    deps: [games]
  });
  const coachingPostReq = usePost({
    uri: "/api/modifyCoachAttendance",
    deps: [upcomingGamesToCoach]
  });
  const availabilities = useGet<Game[]>(
      `/api/subbingAvailabilities?personId=${user.personId}`
  );
  const availabilityPostReq = usePost({
    uri: "/api/updateSubbingAvailability",
    deps: [availabilities]
  });

  const now = dtNow();

  // Keep games on 'upcoming' up to two hours after the game is scheduled to start. This accounts for games starting
  // late, or changes to games right after it finishes.
  const twoHoursBeforeNow = addTimeToNow(0, -2);
  const upcomingGames = (games.data || []).filter(game => game.date >= twoHoursBeforeNow);

  function gamesInNextTwoWeeks(games: Game[]) {
    const twoWeeksFromNow = nDaysFromNow(14);
    const upcomingGames = games.filter(
        game => game.date >= now && game.date <= twoWeeksFromNow
    );
    return _.sortBy(upcomingGames, game => game.date);
  }

  const gamesPlayed = (games.data || [])
      .filter(game => game.date < now)
      .filter(game => game.playerAttendance === Attendance.IN).length;
  const gamesLeftInSeason = upcomingGames.length;

  const nextFourGames = _.sortBy(upcomingGames, game => game.date).slice(0, 4);
  const nextTwoGamesToCoach = _.sortBy(
      upcomingGamesToCoach.data || [],
      game => game.date
  ).slice(0, 2);
  const upcomingSubbingOpportunities = gamesInNextTwoWeeks(
      availabilities.data || []
  );
  const showSubbingBanner = prefs.data === null;
  const iscoach = nextTwoGamesToCoach.length > 0;

  function spinWhileLoading(value: ReactText) {
    if (!games.data) {
      return <Spinner size={"small"}/>;
    }
    return value;
  }

  return (
      <>
        <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              padding: "0 5px 0 5px"
            }}
        >
          {bannerInfo.data && bannerInfo.data.registrationSeason && <Alert
              message={"Registration Is Open"}
              description={
                <span>
                <Link to={SeasonRegistrationUrl.createUrl(`${bannerInfo.data!.registrationSeason}`)}>Click here</Link> to register for the next season
              </span>
              }
              type={"info"}
              showIcon={false}
          />}
          <Statistic
              title="Games Played"
              value={gamesPlayed}
              formatter={spinWhileLoading}
              style={{margin: "0 5px 0 5px"}}
          />
          <Statistic
              title="Games Left"
              value={gamesLeftInSeason}
              formatter={spinWhileLoading}
              style={{margin: "0 5px 0 5px"}}
          />
        </div>
        {showSubbingBanner && (
            <Alert
                message={"Want to sub?"}
                description={
                  <span>
              Go to <Link to={SUBBING}>subbing preferences</Link> to
              choose your preferred positions and allow captains to access your
              contact information.
            </span>
                }
                type={"info"}
                showIcon={true}
            />
        )}
        <Divider>Upcoming Games</Divider>
        <div>
          <p>
            Below are your next two games. Click{" "}
            <Link to={SCHEDULE}>here</Link> to
            {user.teamUsesJvite ? " RSVP for games for" : " view"} the entire
            season.
          </p>
        </div>
        <AttendanceTable
            games={games.data ? nextFourGames : undefined}
            isLoading={postReq.isLoading || games.isLoading}
            showAttendanceButtons={user.teamUsesJvite}
            onClick={(row, modifyType) =>
                postReq.send({
                  gameId: row.id,
                  teamId: user.teamId,
                  personId: user.personId,
                  modifyType: toModifyType(modifyType, row.playerAttendance)
                })
            }
            showSubIndicator={true}
        />
        {iscoach && (
            <>
              <Divider>Upcoming Games to Coach</Divider>
              <div>
                <p>
                  Below are the next two games you are coaching. Click{" "}
                  <Link to={SCHEDULE}>here</Link> to RSVP for games for the
                  entire season.
                </p>
              </div>
              <AttendanceTable
                  games={upcomingGamesToCoach.data ? nextTwoGamesToCoach : undefined}
                  isLoading={
                    coachingPostReq.isLoading || upcomingGamesToCoach.isLoading
                  }
                  showAttendanceButtons={true}
                  onClick={(row, modifyType) =>
                      coachingPostReq.send({
                        gameId: row.id,
                        teamId: row.playerAttendanceTeamId,
                        coachId: user.personId,
                        modifyType: toModifyType(modifyType, row.playerAttendance)
                      })
                  }
              />
            </>
        )}
        <Divider>Other Teams' Games</Divider>
        <div>
          <p>
            Mark yourself as available or unavailable to sub in upcoming games -
            captains can see your responses to help them find subs (if needed).
            <br/>
            Click <Link to={SUBBING}>here</Link> to mark your
            availabilities for the full schedule.
          </p>
        </div>
        <AttendanceTable
            games={availabilities.data ? upcomingSubbingOpportunities : undefined}
            isLoading={availabilityPostReq.isLoading || availabilities.isLoading}
            showAttendanceButtons={true}
            onClick={(row, modifyType) =>
                updateAvailability(availabilityPostReq, user, row, modifyType)
            }
            attendanceColLabel={"Available"}
        />
      </>
  );
}
