import React from "react";
import { useCookies } from "react-cookie";
import { Button, Layout } from "antd";
import { LoggedInContainer } from "./common/pages/LoggedInContainer";
import { useUserContext } from "./common/contexts";
import { AppEngineService, SERVICE_NAME } from "./common/constants";
import { usePost } from "./common/hooks";
import { User } from "./common/interfaces";
import Spinner from "./common/components/Spinner";
import { IS_MOBILE } from "./common/utils";
import { Logo } from "./common/components/Logo";
import {Navigate, Route, Routes, useNavigate} from "react-router";
import {LOGIN, REGISTER} from "./common/routes";
import {
  DONATE,
  PASSWORD_RESET,
  SeasonRegistrationUrl
} from "./players/routes";
import {Donate} from "./players/pages/Donate";
import {SeasonRegistration} from "./players/pages/SeasonRegistration";
import {PasswordReset} from "./players/pages/PasswordReset";
import {Registration} from "./players/pages/Registration";
import {LoginPage} from "./common/pages/LoginPage";

const App: React.FC = () => {
  const [cookies, , removeCookie] = useCookies(["username", "authToken"]);
  const { user, setUser } = useUserContext();
  let navigate = useNavigate();
  const postReq = usePost({ uri: "/api/user" });

  if (postReq.isLoading) {
    return <Spinner size={"large"} />;
  }

  const onLogout = () => {
    removeCookie("username", { path: "/" });
    removeCookie("authToken", { path: "/" });
    setUser(undefined);
    navigate("/");
  };

  // User isn't set but the cookies are present; log the user in.
  if (!user && cookies["username"] && cookies["authToken"]) {
    postReq.send({}, (data: User) => setUser(data), onLogout);
    return <Spinner size={"large"} />;
  }

  return (
    <Layout>
      {(SERVICE_NAME !== AppEngineService.PLAYERS || !IS_MOBILE) && (
        <Layout.Header style={{ background: "white" }}>
          <Logo />
          {user && (
            <Button
              type={"primary"}
              className={"logout-button"}
              onClick={onLogout}
            >
              Log out
            </Button>
          )}
        </Layout.Header>
      )}
      <Layout.Content style={{ padding: IS_MOBILE ? "1vw" : "3vw" }}>
        {!user && (
            <Routes>
              <Route path={"/"} element={<LoginPage />} />
              <Route path={DONATE} element={<Donate />} />
              <Route path={LOGIN} element={<LoginPage />} />
              <Route
                  path={SeasonRegistrationUrl.pattern}
                  element={<SeasonRegistration />}
              />
              <Route
                  path={PASSWORD_RESET}
                  element={<PasswordReset />}
              />
              <Route
                  path={REGISTER}
                  element={<Registration />}
              />
              <Route path={"*"} element={<Navigate to="/" />} />
            </Routes>
        )}
        {user && (<LoggedInContainer/>)}
      </Layout.Content>
    </Layout>
  );
};

export default App;
